import { Field } from 'formik';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@summer/ui-components/src';

import FilesUpload from 'components/shared/FilesUpload/FilesUpload';
import { trackingEvents } from 'constants/trackingEvents';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  clearFilesErrors,
  deleteFile,
  removeFileWithError,
  uploadFileV4,
} from 'redux/actions/fileCollections.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';

const TuitionFileUpload = ({
  fileCollection,
  collection,
  value,
  setFieldValue,
  setFieldTouched,
  name,
}) => {
  const dispatch = useDispatch();
  const serviceType = useSelector(getTuitionReimbursementServiceType);
  useEffect(() => {
    setFieldTouched(name, false);
  }, [name, setFieldTouched]);

  useEffect(() => {
    // only gets called once the update has been confirmed in redux
    setFieldValue(name, fileCollection);
    setFieldTouched(name, true);
  }, [setFieldValue, setFieldTouched, name, fileCollection]);

  const sendEATrackUploadSuccess = useCallback(() => {
    if (serviceType) {
      const uploadSuccessEvents = {
        tuitionReimbursementCompletionProof:
          trackingEvents.EDU_ASSIST[serviceType]
            .ADD_PROOF_COMPLETION_FILE_UPLOADED,
        tuitionReimbursementPaymentProof:
          trackingEvents.EDU_ASSIST[serviceType]
            .ADD_PROOF_RECEIPT_FILE_UPLOADED,
        tuitionReimbursementAttachment:
          trackingEvents.EDU_ASSIST[serviceType]
            .COURSE_REQUEST_UPLOAD_FILE_UPLOADED,
      };
      dispatch(
        trackServerSide(uploadSuccessEvents[collection], {
          integrations: { All: true },
        }),
      );
    }
  }, [collection, dispatch, serviceType]);

  const sendEATrackUploadClicked = useCallback(() => {
    if (serviceType) {
      const uploadClickedEvents = {
        tuitionReimbursementCompletionProof:
          trackingEvents.EDU_ASSIST[serviceType]
            .ADD_PROOF_COMPLETION_FILE_CLICKED,
        tuitionReimbursementPaymentProof:
          trackingEvents.EDU_ASSIST[serviceType].ADD_PROOF_RECEIPT_FILE_CLICKED,
        tuitionReimbursementAttachment:
          trackingEvents.EDU_ASSIST[serviceType]
            .COURSE_REQUEST_UPLOAD_FILE_CLICKED,
      };
      dispatch(
        trackServerSide(uploadClickedEvents[collection], {
          integrations: { All: true },
        }),
      );
    }
  }, [collection, dispatch, serviceType]);

  return (
    <Field name={name}>
      {({ meta }) => {
        return (
          <>
            <FilesUpload
              clearFilesErrors={clearFilesErrors}
              handleFileUpload={file => {
                dispatch(
                  uploadFileV4(
                    { collection, file },
                    {
                      onSuccess: () => sendEATrackUploadSuccess,
                    },
                  ),
                );
              }}
              handleDeleteFile={fileId => {
                dispatch(deleteFile({ collection, fileId }));
              }}
              handleRemoveFileWithError={fileName => {
                dispatch(removeFileWithError({ collection, fileName }));
              }}
              onClick={sendEATrackUploadClicked}
              fileList={value}
              collectionName={collection}
              dropZoneHeight="104px"
              dropZoneOnTop={true}
            />
            <Text size="small" color="error">
              {meta.touched && meta.error}
            </Text>
          </>
        );
      }}
    </Field>
  );
};

export default TuitionFileUpload;
