import { composeWithDevTools } from '@redux-devtools/extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import { identifyAction } from 'redux/actions/analytics.actions';
import analyticsMiddleware from 'redux/middleware/analytics.middleware';
import apiMiddleware from 'redux/middleware/api.middleware';
import authMiddleware from 'redux/middleware/auth.middleware';
import cmsMiddleware from 'redux/middleware/cms.middleware';
import collegeSavingsPlannerMiddleware from 'redux/middleware/collegeSavingsPlanner.middleware';
import constantsMiddleware from 'redux/middleware/constants.middleware';
import datadogMiddleware from 'redux/middleware/datadog.middleware';
import emergencySavingsMiddleware from 'redux/middleware/emergencySavings.middleware';
import fileCollectionsMiddleware from 'redux/middleware/fileCollections.middleware';
import forgivenessFinderMiddleware from 'redux/middleware/forgivenessFinder.middleware';
import idrMiddleware from 'redux/middleware/idr.middleware';
import loansMiddleware from 'redux/middleware/loans.middleware';
import navigationMiddleware from 'redux/middleware/navigation.middleware';
import oauthMiddleware from 'redux/middleware/oauth.middleware';
import onboardingMiddleware from 'redux/middleware/onboard.middleware';
import paywallMiddleware from 'redux/middleware/paywall.middleware';
import plaidMiddleware from 'redux/middleware/plaid.middleware';
import profileMiddleware from 'redux/middleware/profile.middleware';
import PSLFMiddleware from 'redux/middleware/pslf.middleware';
import recEngineMiddleware from 'redux/middleware/recEngine.middleware';
import retirementMatchMiddleware from 'redux/middleware/retirementMatch.middleware';
import s3Middleware from 'redux/middleware/s3.middleware';
import signatureRequestMiddleware from 'redux/middleware/signatureRequests.middleware';
import slcMiddleware from 'redux/middleware/slc.middleware';
import smsFactorMiddleware from 'redux/middleware/smsFactor.middleware';
import syncMiddleware from 'redux/middleware/sync.middleware';
import trackingMiddleware from 'redux/middleware/tracking.middleware';
import tuitionReimbursementMiddleware from 'redux/middleware/tuitionReimbursement.middleware';
import userMiddleware from 'redux/middleware/user.middleware';
import verifyUserOtpMiddleware from 'redux/middleware/verifyUserOtp.middleware';
import idrCalculations from 'redux/reducers/IDR/calculations.reducer';
import currentIdrForm from 'redux/reducers/IDR/currentIdrForm.reducer';
import idrForms from 'redux/reducers/IDR/idrForms.reducer';
import idrNetworkSuccess from 'redux/reducers/IDR/networkSuccess.reducer';
import submit from 'redux/reducers/IDR/submit.reducer';
import supportingDocuments from 'redux/reducers/IDR/supportingDocuments.reducer';
import yourLoans from 'redux/reducers/IDR/yourLoans.reducer';
import ccpCalculatorDataReducer from 'redux/reducers/collegeSavingsPlanner/calculatorData.reducer';
import ccpInputsReducer from 'redux/reducers/collegeSavingsPlanner/inputs.reducer';
import ccpResultsReducer from 'redux/reducers/collegeSavingsPlanner/results.reducer';
import cspScholarshipDatabaseReducer from 'redux/reducers/collegeSavingsPlanner/scholarshipDatabase.reducer';
import loanTypes from 'redux/reducers/constants/loanTypes.reducer';
import repaymentPlanTypes from 'redux/reducers/constants/repaymentPlanTypes.reducer';
import servicers from 'redux/reducers/constants/servicers.reducer';
import currentUser from 'redux/reducers/currentUser.reducer';
import emergencySavings from 'redux/reducers/emergencySavings.reducer';
import fileCollections from 'redux/reducers/fileCollections.reducer';
import financialProfile from 'redux/reducers/financialProfile.reducer';
import flags from 'redux/reducers/flags.reducer';
import forgivenessFinder from 'redux/reducers/forgivenessFinder.reducer';
import loans from 'redux/reducers/loans.reducer';
import nyc from 'redux/reducers/nyc';
import onboarding from 'redux/reducers/onboard.reducer';
import plaidIntegrations from 'redux/reducers/plaidIntegrations.reducer';
import plaidLoans from 'redux/reducers/plaidLoans.reducer';
import plaidTransactions from 'redux/reducers/plaidTransactions.reducer';
import profile from 'redux/reducers/profile.reducer';
import pslfCalculations from 'redux/reducers/pslf/calculations.reducer';
import currentPslfForm from 'redux/reducers/pslf/currentPslfForm.reducer';
import pslfNetworkSuccess from 'redux/reducers/pslf/networkSuccess.reducer';
import pslfForms from 'redux/reducers/pslf/pslfForms.reducer';
import recEngine from 'redux/reducers/recEngine.reducer';
import resetReducer from 'redux/reducers/resetReducer.reducer';
import retirementMatch from 'redux/reducers/retirementMatch.reducer';
import signatureRequests from 'redux/reducers/signatureRequests.reducer';
import slcReducer from 'redux/reducers/slc.reducer';
import smsFactor from 'redux/reducers/smsFactor.reducer';
import summerSaveReducer from 'redux/reducers/summerSave.reducer';
import sync from 'redux/reducers/sync.reducer';
import tuitionReimbursement from 'redux/reducers/tuitionReimbursement.reducer';
import connectFsaModal from 'redux/reducers/ui/connectFsaModal.reducer';
import idr from 'redux/reducers/ui/idr.reducer';
import loading from 'redux/reducers/ui/loading.reducer';
import loanForm from 'redux/reducers/ui/loanForm.reducer';
import s3UploadProgress from 'redux/reducers/ui/s3UploadProgress.reducer';
import serverErrors from 'redux/reducers/ui/serverErrors.reducer';
import settings from 'redux/reducers/ui/settings.reducer';
import sidebar from 'redux/reducers/ui/sidebar.reducer';
import toast from 'redux/reducers/ui/toast.reducer';
import tuitionReimbursementUi from 'redux/reducers/ui/tuitionReimbursement.reducer';

import history from './history';

const ENV = import.meta.env.REACT_APP_NODE_ENV || import.meta.env.NODE_ENV;

export const rootReducer = resetReducer(
  combineReducers({
    router: connectRouter(history),
    currentUser,
    onboarding,
    loans,
    plaidLoans,
    constants: combineReducers({
      loanTypes,
      servicers,
      repaymentPlanTypes,
    }),
    ui: combineReducers({
      serverErrors,
      loading,
      loanForm,
      sidebar,
      toast,
      settings,
      connectFsaModal,
      s3UploadProgress,
      idr,
      tuitionReimbursementUi,
    }),
    IDR: combineReducers({
      supportingDocuments,
      calculations: idrCalculations,
      yourLoans,
      submit,
      currentIdrForm,
      idrForms,
      networkSuccess: idrNetworkSuccess,
    }),
    profile,
    pslf: combineReducers({
      calculations: pslfCalculations,
      currentPslfForm,
      pslfForms,
      networkSuccess: pslfNetworkSuccess,
    }),
    fileCollections,
    financialProfile,
    sync,
    forgivenessFinder,
    signatureRequests,
    recEngine,
    flags,
    smsFactor,
    collegeSavingsPlanner: combineReducers({
      calculatorData: ccpCalculatorDataReducer,
      inputs: ccpInputsReducer,
      results: ccpResultsReducer,
      scholarshipDatabase: cspScholarshipDatabaseReducer,
    }),
    retirementMatch,
    plaidIntegrations,
    plaidTransactions,
    tuitionReimbursement,
    emergencySavings,
    slc: slcReducer,
    summerSave: summerSaveReducer,
    nyc,
  }),
);

export const middleware = [
  navigationMiddleware,
  authMiddleware,
  userMiddleware,
  constantsMiddleware,
  loansMiddleware,
  idrMiddleware,
  onboardingMiddleware,
  PSLFMiddleware,
  fileCollectionsMiddleware,
  s3Middleware,
  apiMiddleware,
  routerMiddleware(history),
  trackingMiddleware,
  syncMiddleware,
  oauthMiddleware,
  forgivenessFinderMiddleware,
  cmsMiddleware,
  signatureRequestMiddleware,
  recEngineMiddleware,
  verifyUserOtpMiddleware,
  profileMiddleware,
  smsFactorMiddleware,
  datadogMiddleware,
  paywallMiddleware,
  collegeSavingsPlannerMiddleware,
  plaidMiddleware,
  tuitionReimbursementMiddleware,
  retirementMatchMiddleware,
  emergencySavingsMiddleware,
  slcMiddleware,
];

// services only in production
if (ENV === 'production') {
  middleware.push(analyticsMiddleware);
}

const persistConfig = {
  key: '@@SUMMER',
  whitelist: [
    'currentUser',
    'onboarding',
    'ui',
    'IDR',
    'fileCollections',
    'pslf',
    'profile',
    'financialProfile',
    'recEngine',
    'flags',
    'smsFactor',
  ],
  storage,
  stateReconciler: autoMergeLevel2,
};

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware),
);

export const persistor = persistStore(store, null, () => {
  store.dispatch(identifyAction());
});

if (ENV === 'development' || ENV === 'test') {
  window.store = store;
}

export default store;
